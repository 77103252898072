
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  methods: {
    zoomIn() {
      if (!this.$el.querySelector('.cover-image')) return

      this.$gsap.to(this.$el.querySelector('.cover-image'), {
        filter: 'blur(4px)',
      })
    },

    zoomOut() {
      if (!this.$el.querySelector('.cover-image')) return

      this.$gsap.to(this.$el.querySelector('.cover-image'), {
        filter: 'blur(0px)',
      })
    },
  },
}
