
export default {
  props: {
    white: {
      type: Boolean,
      default: false,
    },

    black: {
      type: Boolean,
      default: false,
    },

    size: {
      type: String,
      default: '',
    },
  },
}
